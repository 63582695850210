import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'
// var interleaveOffset = 0.5; //视差比值
var bannerswiper = new Swiper('.index-banner .swiper-container', {
    loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        waitForTransition: false,
    },
    // navigation: {
    //   nextEl: ".index-banner .swiper-button-next",
    //   prevEl: ".index-banner .swiper-button-prev",
    // },
    pagination: {
        el: '.index-banner .swiper-pagination',
        clickable: true
    },
    // on: {
    //     progress: function (progress) {
    //         for (var i = 0; i < this.slides.length; i++) {
    //             var slideProgress = this.slides[i].progress;
    //             var innerOffset = this.width * interleaveOffset;
    //             var innerTranslate = slideProgress * innerOffset;
    //             this.slides[i].querySelector(".slide-inner").style.transform =
    //                 "translate3d(" + innerTranslate + "px, 0, 0)";
    //         }
    //     },
    //     touchStart: function () {
    //         for (var i = 0; i < this.slides.length; i++) {
    //             this.slides[i].style.transition = "";
    //         }
    //     },
    //     setTransition: function () {
    //         for (var i = 0; i < this.slides.length; i++) {
    //             this.slides[i].style.transition = "1000ms";
    //             this.slides[i].querySelector(".slide-inner").style.transition =
    //                 "1000ms";
    //         }
    //     }
    // }
});

var indexNewsSwiper = new Swiper(".index-news .swiper-container", {
  slidesPerView:1,
  spaceBetween: 30,
  speed:800,
  navigation: {
    nextEl: ".index-news .swiper-button-next",
    prevEl: ".index-news .swiper-button-prev",
  },
});

var indexProsSwiper = new Swiper(".index-pro .swiper-container", {
  slidesPerView: 1,
  spaceBetween: 30,

  breakpoints: { 
    767: { 
      slidesPerView: 2,
    },
    1360: {  
      slidesPerView: 3,
    },
    1499: {  
      slidesPerView: 4,
      spaceBetween: 30
    },
    1600: {  
      slidesPerView: 5,
      spaceBetween: 30
    }
  },
  speed:800,
  navigation: {
    nextEl: ".index-pro .swiper-button-next",
    prevEl: ".index-pro .swiper-button-prev",
  },
});
